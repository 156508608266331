
import { Component, Vue } from 'vue-property-decorator';

// import API
import { adGroupTypeCreate, adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';

@Component
export default class AdGroupTypeCreate extends Vue {

    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        this.resetInput();
    }
    public resetInput() {
        this.inputName = "";
        this.inputDescription = "";
    }

    //
    public async createAdGroupType() {
        try {
            if ((! this.inputName) || (! this.inputDescription)) {
                return;
            }
            await adGroupTypeCreate(this.inputName, this.inputDescription);
            await adGroupTypeReadAll();
            this.resetInput();
        }
        catch(error) {
            // SHOW ERROR
        }
    }
};
