
import { Component, Prop, Vue } from 'vue-property-decorator';

// import API
import { categoryCreate, categoryReadAll } from '@/apiClient/categoryEndpoints';

@Component
export default class CategoryCreate extends Vue {

    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        this.resetInput();
    }
    public resetInput() {
        this.inputName = "";
        this.inputDescription = "";
    }
    public async createCategory() {
        try {
            await categoryCreate(this.inputName, this.inputDescription);
            await categoryReadAll();
            this.resetInput();
        }
        catch(error) {

        }

    }
};
