
import { Component, Vue } from 'vue-property-decorator';

// import components
import AdGroupTypeCreate from '../components/adGroupType/AdGroupTypeCreate.vue';
import AdGroupTypeEdit from '../components/adGroupType/AdGroupTypeEdit.vue';
import AdGroupTypeList from '../components/adGroupType/AdGroupTypeList.vue';

import CampaignTypeCreate from '../components/campaignType/CampaignTypeCreate.vue';
import CampaignTypeEdit from '../components/campaignType/CampaignTypeEdit.vue';
import CampaignTypeList from '../components/campaignType/CampaignTypeList.vue';

import CategoryCreate from '../components/category/CategoryCreate.vue';
import CategoryList from '../components/category/CategoryList.vue';
import CategoryEdit from '../components/category/CategoryEdit.vue';

import SystemControls from '../components/system/SystemControls.vue';

@Component({
    components: {
        AdGroupTypeList,
        AdGroupTypeEdit,
        AdGroupTypeCreate,
        CampaignTypeCreate,
        CampaignTypeEdit,
        CampaignTypeList,
        CategoryCreate,
        CategoryList,
        CategoryEdit,
        SystemControls
    },
})
export default class SystemManagementPage extends Vue {

    private adGroupTypeView: boolean = false;
    private campaignTypeView: boolean = false;
    private categoryView: boolean = true;
    private systemControlsView: boolean = false;

    public hideAll() {
        this.adGroupTypeView = false;
        this.campaignTypeView = false;
        this.categoryView = false;
        this.systemControlsView = false;
    }
    // AD GROUP TYPE
    public toggleAdGroup() {
        this.hideAll();
        this.adGroupTypeView = true;
    }
    // CAMPAIGN TYPE
    public toggleCampaignType() {
        this.hideAll();
        this.campaignTypeView = true;
    }
    // CATEGORY CREATE
    public toggleCategory() {
        this.hideAll();
        this.categoryView = true;
    }
    // SYSTEM CONTROLS
    public toggleSystemControls() {
        this.hideAll();
        this.systemControlsView = true;
    }
};
