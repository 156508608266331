
import { Component, Vue, Watch } from 'vue-property-decorator';

// import API
import { adGroupTypeRead, adGroupTypeReadAll, adGroupTypeUpdate } from '@/apiClient/adGroupTypeEndpoints';

@Component
export default class AdGroupTypeEdit extends Vue {

    private adGroupTypeId?: number;

    private loaded: boolean = false;

    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public async mounted() {
        console.log("mounted()");
        this.resetInput();
    };

    @Watch('$store.state.adGroupTypeCache.adGroupTypeId')
    public internalUpdate(newValue: number, oldValue: number) {
        adGroupTypeRead(newValue).then((adGroupType) => {
            this.loaded = true;
            this.adGroupTypeId = adGroupType.id;

            this.inputName = adGroupType.name;
            this.inputDescription = adGroupType.description;
            // scroll up
            window.scrollTo(0,0);
        });

    }
    public resetInput() {
        this.inputName = "";
        this.inputDescription = "";
    }
    public async updateAdGroupType() {
        try {
            await adGroupTypeUpdate(this.$store.state.adGroupTypeCache.adGroupTypeId, this.inputName, this.inputDescription);
            this.resetInput();
            this.$store.commit("updateAdGroupTypeCache", {
                adGroupTypeId: null
            });
            this.loaded = false;
            await adGroupTypeReadAll();
        }
        catch(error) {
            console.log("EW");
        }

    }
};
