
import { Component, Prop, Vue } from 'vue-property-decorator';

// import API
import { campaignTypeCreate, campaignTypeReadAll } from '@/apiClient/campaignTypeEndpoints';
import { categoryCreate } from '@/apiClient/categoryEndpoints';

@Component
export default class CampaignTypeCreate extends Vue {

    private inputCategory: string = "";
    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        this.resetInput();
    }
    public resetInput() {
        this.inputName = "";
        this.inputDescription = "";
        this.inputCategory = "";
    }
    public setCampaignTypeCategory(category: string) {
        this.inputCategory = category;
    }

    //
    public async createCampaignType() {
        if (this.inputCategory !== "") {
            try {
                let categoryName = `${this.inputCategory || '*'}:${this.inputName}`;
                await campaignTypeCreate(categoryName, this.inputDescription);
                await campaignTypeReadAll();
                this.resetInput();
            }
            catch(error) {
                // SHOW ERROR
            }
        }
    }
};
