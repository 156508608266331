
import { Component, Prop, Vue } from 'vue-property-decorator';

// import API
import { campaignTypeReadAll } from "../../apiClient/campaignTypeEndpoints";

@Component
export default class CampaignTypeList extends Vue {

    @Prop()
    private campaignId!: string;

    private campaign: any = {};

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        this.readCampaignTypeList();

    }
    public async readCampaignTypeList() {
        await campaignTypeReadAll();
    }

    public initEdit(campaignTypeId: number) {
        this.$store.commit("updateCampaignTypeCache", {
            campaignTypeId: campaignTypeId
        })
    };
};
