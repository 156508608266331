// import store
import store from "../store/index";

// POST /api/v1/searchterm/yesterday
const searchTermYesterday = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/searchterm/yesterday`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST /api/v1/searchterm/last7days
const searchTermLast7Days = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/searchterm/last7days`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST /api/v1/searchterm/runrecent
const searchTermRunRecent = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/searchterm/runrecent`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "applicaiton/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST /api/v1/searchterm/process
const searchTermProcess = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/searchterm/process`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    searchTermYesterday,
    searchTermLast7Days,
    searchTermRunRecent,
    searchTermProcess
};